<template>
  <div class="text-center" >
    <v-dialog
      v-model="dialog_from_input"
      max-width="950px"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
        <v-dialog
          v-model="progress"
          width="200px"
          scrollable
        >
        <v-card
            color="primary"
            dark
            v-if="progress"
            width="150px"
        >
          <v-card-text>
            Please stand by
          <v-progress-linear
          width="150px"
          indeterminate
          color="white"
          class="mb-0"
          ></v-progress-linear>
          </v-card-text>
        </v-card>
        </v-dialog>

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_datas ? 'Edit' : 'Create'}} Product Part
            <v-spacer></v-spacer>
          <span style="cursor:pointer; margin-right:10px;" @click="openProduct"><v-icon >mdi mdi-clipboard-list-outline</v-icon></span>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>
                      <v-col cols="12" md="5" style="border-right:1px solid">
                          <v-autocomplete
                          v-model="product_id"
                          :items="products"
                          chips
                          label="Product"
                          item-text="name"
                          item-value="id"
                          :rules="nameRules"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('product')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('product',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('product',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="7">
                          <v-list-item
                            v-for="(item) in cart"
                            :key="item.id"
                          >
                          <v-list-item-avatar>
                           <v-btn icon small rounded depressed @click="dialogRemoveItemCart(item.id)">
                                <span  style="color:red">X</span>
                            </v-btn>
                          </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="custom-list-item-title-modal" v-text="item.name"></v-list-item-title>
                              <v-list-item-subtitle class="custom-list-item-subtitle-modal"> {{item.qty}}</v-list-item-subtitle>

                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon small rounded depressed @click="setQuantity({action:'removeQuantity',id:item.id})" :disabled="item.qty <= 1">
                                    <v-icon dark color="error">remove_circle</v-icon>
                              </v-btn>

                            </v-list-item-action>
                            <v-list-item-action class="text-center">
			                        <input type="number" v-model="item.qty" style="width:60px; border-bottom:1px solid black" text-xs-center>
                            </v-list-item-action>
                            <v-list-item-action style="margin-left:0px !important">
                                  <v-btn icon small rounded depressed @click="setQuantity({action:'addQuantity',id:item.id})" :key="item.id">
                                    <v-icon dark color="success">add_circle</v-icon>
                                  </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                      </v-col>
                    </v-row>
               </v-form>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_datas ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <ProductCompnent v-on:close="openProduct" v-if="dialog_product"/>
    <dialogDelete :dialog="dialog_remove_item" data="Item" v-on:close="dialogRemoveItemCart" v-on:deleteAction="removeItem"/>

  </div>

</template>

<script>
import dialogDelete from '../../../../components/external/DialogDelete'
import { mapActions, mapGetters } from 'vuex'
import ProductCompnent from './product'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  components:{
    ProductCompnent,
    dialogDelete
  },
  methods: {
    ...mapActions({
      setData:'data/setData',
      setQuantity:'cart/setQuantity',
      setCart:'cart/setCart',
    }),

    async send_data() {
      let cart = await this.cart.map(x => {
        var data_cart = {
          base_price: parseInt(x.base_price),
          begin_price: parseInt(x.begin_price),
          price: parseInt(x.base_price),
          part_product_id: x.id,
          qty: x.qty,
        }
        return data_cart
      })
      const data = {
        product_id: this.product_id,
        company_id: this.user.company_id,
        product_parts: cart,
        entry_by: this.user.fullname,
      }
      return data
    },
    async save () {
      this.loading = true
      const data = JSON.stringify(await this.send_data())
      await this.axios.post('v1/product-part/list/create',data,{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go()
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    remove(data) {
      this[data] = ''
    },
    async update() {
      const data = JSON.stringify(await this.send_data())
      await this.axios.post('v1/product-part/list/create',data,{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go()

          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.setCart([])
      this.$emit('close')
    },
    go() {
      this.$emit('go')
    },
    dialogRemoveItemCart(id_data = null) {
      this.id_remove_item = id_data
      this.dialog_remove_item =!this.dialog_remove_item
    },
    removeItem() {
      this.setQuantity({
        action:'removeItem',
        id:this.id_remove_item
      })
      this.dialogRemoveItemCart()
    },
    reset () {
       this.product_id = ''
    },
    img: function (folder,file) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
    openProduct() {
      this.dialog_product = !this.dialog_product
    },

    async getDetail(id) {
        this.progress = true
       await this.axios.get('v1/product-part/list/detail/' + id)
      .then(async (ress) => {
          if (ress.data.data.length) {
              let cart = await ress.data.data[0].product_parts.map( x => {
              let product = {
                id:x.part_product.id,
                qty: parseInt(x.qty),
                pesan: parseInt(x.qty),
                name:x.part_product.name,
                checkhed:true
                }
                return product
              })
            this.setCart(cart)
          }

      })
      .catch((err) => console.log(err.response))
      this.progress = false
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog_product:false,
    dialog_remove_item:false,
    id_remove_item:'',
    progress:false,
    product_id: '',
    note: '',
    branch: '',
    payment: '',
    vendor: '',
    document:'',
    imgurl: 'img/no_img.png',
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    nameRules: [
      v => !!v || 'Field is required'
    ],
  }),

  computed: {
    ...mapGetters({
      products: 'data/products',
      cart:'cart/cart',
      edit_data:'cart/edit_data',
      edit_datas:'data_edit'
    }),
  },

  async created () {

     if (this.edit_datas) {
          this.product_id = this.edit_datas.id
          await this.getDetail(this.edit_datas.id)
        }
            await this.get_data_master('product','products')



  }
}
</script>
