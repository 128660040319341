<template>
  <div>
    <Progress v-if="loading" />
    <v-card tile v-if="!loading">
      <v-card-text class="text-center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="8" align="right">
              <v-btn color="primary" medium tile elevation="1" @click="add()">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="product_parts"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
        >
          <template v-slot:item.name="{ item }">
            <span @click="openDetail(item)">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="edit(item)" color="success">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>

    <formInput
      v-on:go="go('product-part/list/all', 'product_parts')"
      v-on:close="close"
      v-if="dialog_form"
    />
    <dialogDelete
      :dialog="dialog_delete"
      data="Inventory in"
      v-on:close="close"
      v-on:deleteAction="deleteAction"
    />
    <DetailDialog
      :data="data_detail"
      v-if="dialog_detail"
      v-on:close="openDetail"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Mixin from "./mixin";
import { mapGetters } from "vuex";

export default {
  mixins: [Mixin],
  computed: {
    ...mapGetters({
      product_parts: "data/product_parts"
    })
  },
  async created() {
    if (this.product_parts.length) {
      this.loading = false;
    }
  },
  async mounted() {
    await this.go("product-part/list/all", "product_parts");
  }
};
</script>
