import Progress from '../../../components/external/Progress'
import { mapActions, mapGetters } from 'vuex'
import formInput from './components/form'
import dialogDelete from '../../../components/external/DialogDelete'
import dialogDetail from './components/detail'
import GlobalMixin from '../../mixin/index'
export default {
  mixins:[GlobalMixin],
  data () {
    return {
      dialog_delete:false,
      dialog_detail:false,
      data_detail:{},
      from:new Date('2021-01-01').toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      from_menu:false,
      to_menu:false,
      branch_id:'',
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'action'
        }

      ]
    }
  },
  components: {
    Progress,
    formInput,
    dialogDelete,
    "DetailDialog" : dialogDetail
  },
  methods: {
    ...mapActions({
      setEditData:'cart/setEditData',
      setEditDatas:'setDataEdit',
      setCart:'cart/setCart'
    }),

    openDetail: function (item = {}) {
      this.data_detail = item
      this.dialog_detail = !this.dialog_detail
    },
    async go (table,store) {
      await this.axios.get('/v1/'+ table +'?company_id=' + this.user.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
    async edit (item) {

      await this.add()
      this.setEditDatas(item)
    },
    async deleteAction() {
      const data = {
        is_deleted: 'Y',
        update_by: this.user.fullname,
        id: this.data_delete.id,
      }
      await this.axios.put('v1/product-part/delete/' + this.data_delete.id,JSON.stringify(data),{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success delete data',
            color_snakbar: 'success'
          })
          await this.go('product-part/list/all','product_parts')
          this.close()
        })
        .catch((err) => console.log(err.response))
    },
  },
}
