<template>
  <div class="text-center" >
    <v-dialog
        v-model="progress"
        width="200px"
        scrollable
        v-if="progress"
      >
      <v-card
          color="primary"
          dark
          v-if="progress"
          width="150px"
      >
        <v-card-text>
          Please stand by
        <v-progress-linear
        width="150px"
        indeterminate
        color="white"
        class="mb-0"
        ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="950px"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
      v-else
    >
      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          Detail Product Part
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                  <tr>
                    <td class="text-left">
                      Product
                    </td>
                    <td class="text-left" colspan="2">
                      {{items.name}}
                    </td>
                  </tr>

                  <tr>
                    <td :rowspan="items.product_parts.length + 2">product Part</td>
                  </tr>
                  <tr>
                    <td>Product</td>
                    <td>Quantity</td>
                  </tr>
                  <tr v-for="item in items.product_parts" :key="item.id">
                    <td>
                      {{item.part_product.name}}
                    </td>
                    <td class="text-right">
                      {{item.qty}} {{item.part_product.uom.kode}}
                    </td>
                  </tr>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="css" scoped>
    table {
      border-collapse: collapse;

    }
    table, th, td {
    border: 1px solid #efefef;
    }
    td{
      padding:10px
    }
</style>
<script>
import DetailInventoryMixin from '../../../mixin/DetailInventory'
export default {
  mixins:[DetailInventoryMixin],
  async created () {
    await this.axios.get('v1/product-part/list/detail/' + this.data.id)
    .then((ress)=> {
      if (ress.data.data.length) {
        this.items = ress.data.data[0]
      }
    })
    .catch((err) => console.log(err.response))
    this.progress = false
  }
}
</script>
